
    <!-- ======= Header ======= -->
    <header id="header-thank">
        <div class="container d-flex align-items-center">

            <a href="index.html" class="logo mr-lg-auto"><img src="../../assets/img/landing/logo.png" alt=""></a>

            <nav class="nav-menu d-none">
                <ul>
                    <li><a href="tel:+912235542771"><i class="fa fa-phone" aria-hidden="true"></i>+91 22-35542771</a></li>
                    <li><a href="mailto:contactus@patchinfotech.com"><i class="fa fa-phone" aria-hidden="true"></i>contactus@patchinfotech.com</a></li>
                </ul>
            </nav>
            <!-- .nav-menu -->

        </div>
    </header>
    <!-- End Header -->

    <main id="main">

        <!-- ======= Thank You Section ======= -->
        <section id="privacy-policy" class="privacy-policy">
            <div class="container" data-aos="fade-up">
                <div class="row">
                    <div class="privacy-policy-content">
                        <p>Patch Infotech is very secure about privacy issues and has no objection to viewers who are accessing its website. Your privacy is important to us and hence the information that we receive about you over the internet is safe and secure as reasonably possible.</p>
                        <p>This Privacy Policy outlines the personal information of the customers that we collect while you interrelate with us over the website. To even up the loss of data or change, our site has implemented professional standard protection efforts.</p>
                        <h3 style="font-size: 28px;">We gather your personal information to:</h3>
                        <ul>
                            <li>Register or subscribe to blog posts, forms, or newsletters.</li>
                            <li>Offer meaningful or more personalized experiences on our website.</li>
                            <li>Get analytics and website performance measured</li>
                            <li>Respond to visitor’s query</li>
                        </ul>
                        <p>Every so often, we engage with selected partners, affiliates, or subsidiaries to perform tasks on our behalf. These third-party service providers have access to personal information to perform their functions but may not use it for other purposes. The personal information collected from the customers is not sold, rented, or shared for any kind of commercial purposes. In case, if you observe that your privacy is disobeyed, you can reach us by dropping a mail on <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>. We assure you to tackle it as a prime concern.</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Thank You Section -->

    </main>
    <!-- End #main -->

    
    <!-- ======= Footer ======= -->
    <footer id="footer" class="d-none d-md-block d-lg-block">

        <div class="footer-top">
            <div class="container">
                <div class="row row-cols-md-5 no-gutters">
                    <div class="col-6">
                        <div class="who-are-box">
                            <h3>Who are we</h3>
                            <ul>
                                <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="what-we-do-box">
                            <h3>What we do</h3>
                            <ul>
                                <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                                <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                                <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                                <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="contact-box">
                            <h3>Contact us</h3>
                            <ul>
                                <li>
                                    <img src="../../assets/img/landing/call.png" class="img-fluid">
                                    <a href="tel:9654854895">+91-9654854895</a>
                                </li>
                                <li>
                                    <img src="../../assets/img/landing/email.png" class="img-fluid">
                                    <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="address-box">
                             <h3>Address</h3>
                             <p>91springboard, Building<br>
                                Number 145,Sector 44Rd,<br>
                                Sector 44,Gurgaon-122003 
                             </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="footer-logo">
                            <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                        </div>
                        <div class="social-links mt-4">
                            <a href="https://www.facebook.com/Patch-Infotech-111521311585621" target="_blank"><img src="../../assets/img/landing/fb.png" class="img-fluid"></a>
                            <a href="https://instagram.com/patchinfotech?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/img/landing/instagram.png" class="img-fluid"></a>
                            <a href="https://www.linkedin.com/company/14373720/admin/" target="_blank"><img src="../../assets/img/landing/linkdin.png" class="img-fluid"></a>
                            <a href="https://twitter.com/patch_infotech" target="_blank"><img src="../../assets/img/landing/tweet.png" class="img-fluid"></a>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                            </div>
                            <div class="col-md-6">
                                <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

     <footer id="footer" class="d-block d-md-none d-lg-none">

        <div class="footer-top" >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="who-are-box">
                            <h3>Who are we</h3>
                            <ul>
                                <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="what-we-do-box">
                            <h3>What we do</h3>
                            <ul>
                                <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                                <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                                <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                                <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="contact-box">
                            <h3>Contact us</h3>
                            <ul>
                                <li>
                                    <img src="../../assets/img/landing/call.png" class="img-fluid">
                                    <a href="tel:9654854895">+91-9654854895</a>
                                </li>
                                <li>
                                    <img src="../../assets/img/landing/email.png" class="img-fluid">
                                    <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="address-box">
                             <h3>Address</h3>
                             <p>91springboard, Building<br>
                                Number 145,Sector 44Rd,<br>
                                Sector 44,Gurgaon-122003 
                             </p>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="footer-logo text-center mt-4">
                            <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                        </div>
                        <div class="social-links text-center  mt-4">
                            <a href="https://www.facebook.com/Patch-Infotech-111521311585621" target="_blank"><img src="../../assets/img/landing/fb.png" class="img-fluid"></a>
                            <a href="https://instagram.com/patchinfotech?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/img/landing/instagram.png" class="img-fluid"></a>
                            <a href="https://www.linkedin.com/company/14373720/admin/" target="_blank"><img src="../../assets/img/landing/linkdin.png" class="img-fluid"></a>
                            <a href="https://twitter.com/patch_infotech" target="_blank"><img src="../../assets/img/landing/tweet.png" class="img-fluid"></a>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <p class="mb-0">&#169;2020 Patch Infotech</p>
                            </div>

                            <div class="col-md-6">
                                <a href="privacy-policy.html" style="color: #fff;">Privacy policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- End Footer -->
    <div id="preloader"></div>
