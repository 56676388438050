<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>DISC </h2>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="boostMainWrap">
            <div class="col-sm-5">
                <div class="boostImageWrap" style="height: auto;">
                    <img src="../../../assets/img/DISC/Group 745.png">
                </div>
            </div>
            <div class="col-sm-7">
                <div class="rightPROduct">
                    <div class="noLogo">
                        <img src="../../../assets/img/DISC/Group 711.png" alt="">
                    </div>
                    <div class="noLogo">
                        <img src="../../../assets/img/DISC/DISC.png" alt="">
                    </div>
                    <div class="textProductRight">
                        <p>Dubai International Sports Conference</p>
                    </div>
                    <div class="rightBottom" style="margin-top: 130px;">
                        <div class="col-sm-12" style="padding-left:0px;">
                            <p class="bottomProductLast">Website Link - <a
                                    href="https://dubaiisc.ae/">https://dubaiisc.ae/</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boostMainWrap thisRight">
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/DISC/Our Porfolio-13 2.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/DISC/Our Porfolio-13 3.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/DISC/Our Porfolio-13.png">
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>