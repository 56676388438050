<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Technologies we cover </h2>
                <p>Product development done right, with breakthrough technologies and Agile processes.</p>
            </div>
        </div>
    </div>
    <div class="container">
        <p div class="technologyTopText">From building a marketplace to serving millions of users to handling a live
            stream of 50K concurrent users, we solve complex problems using cutting edge technologies.</p>
        <div class="serviceSection">
            <div class="headingHome">
                <h2 style="margin-bottom:30px;">Our Technology Stack</h2>
            </div>

        </div>
        <div class="chart deskTech">
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Backend Engineering</h2>
                        <p>We use Java and JavaScript based frameworks for server-side development. We use relational as
                            well as NoSQL databases for faster processing of complex set of data.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Java</li>
                            <li>Node.js</li>
                            <li>MEAN</li>
                            <li>Python</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/java_0.png"></li>
                            <li><img src="../../assets/img/Technologies/mean-stack.png"></li>
                            <li><img src="../../assets/img/Technologies/mongo-db_0.png"></li>
                            <li><img src="../../assets/img/Technologies/my-sql.png"></li>
                            <li><img src="../../assets/img/Technologies/net.png"></li>
                            <li><img src="../../assets/img/Technologies/nod.png"></li>
                            <li><img src="../../assets/img/Technologies/pytons.png"></li>
                            <li><img src="../../assets/img/Technologies/spring_3.png"></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/9.png"></li>
                            <li><img src="../../assets/img/Technologies/10.png"></li>
                            <li><img src="../../assets/img/Technologies/11.png"></li>

                            <li><img src="../../assets/img/Technologies/Bootstrap.png"></li>
                            <li><img src="../../assets/img/Technologies/Polymar.png"></li>
                            <li><img src="../../assets/img/Technologies/vue.png" style="margin-top: 10px;"></li>

                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Frontend Engineering</h2>
                        <p>JavaScript in combination with HTML and CSS has revolutionised the way web and mobile
                            applications are built and we are extremely adept at implementing the JS universe.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>AngularJs</li>
                            <li>React Native</li>
                            <li>React js</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Frameworks</h2>
                        <p>Frameworks to design & support development of Platform, Software & Web Applications with
                            faster delivery & consistency.</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/blaze_0.png"></li>
                            <li><img src="../../assets/img/Technologies/djamgo_0.png"></li>
                            <li><img src="../../assets/img/Technologies/flask_1.png"></li>
                            <li><img src="../../assets/img/Technologies/react_0.png"></li>
                            <li><img src="../../assets/img/Technologies/spring_3.png"></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/13.png"></li>
                            <li><img src="../../assets/img/Technologies/16.png"></li>
                            <li><img src="../../assets/img/Technologies/android_0.png"></li>

                            <li><img src="../../assets/img/Technologies/android-tv.png"></li>
                            <li><img src="../../assets/img/Technologies/flask_1.png"></li>
                            <li><img src="../../assets/img/Technologies/hisense_0.png"></li>
                            <li><img src="../../assets/img/Technologies/lg.png"></li>
                            <li><img src="../../assets/img/Technologies/netrange_1.png"></li>
                            <li><img src="../../assets/img/Technologies/roku_0.png"></li>
                            <li><img src="../../assets/img/Technologies/samsung_1.png"></li>

                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Mobility</h2>
                        <p>Experts in developing experiential applications using native as well as hybrid technologies
                            for mobile, tablets and connected devices.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>iOS</li>
                            <li>Android</li>
                            <li>React js</li>
                            <li>Hybrid</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Cloud & DevOps</h2>
                        <p>We help customers migrate to Cloud and provide on-going DevOps automation & 24*7 incident
                            management services.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>DevOps on AWS</li>
                            <li>DevOps Tools</li>
                            <li>AWS Migration</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/Group 341.png"></li>
                            <li><img src="../../assets/img/Technologies/ansible_0.png"></li>
                            <li><img src="../../assets/img/Technologies/aws_0.png"></li>
                            <li><img src="../../assets/img/Technologies/azure_0.png"></li>
                            <li><img src="../../assets/img/Technologies/Group 342.png"></li>
                            <li><img src="../../assets/img/Technologies/docker_2.png"></li>
                            <li><img src="../../assets/img/Technologies/google-cloud_0.png"></li>
                            <li><img src="../../assets/img/Technologies/jenkins_0.png"></li>
                            <li><img src="../../assets/img/Technologies/Group 343.png"></li>
                            <li><img src="../../assets/img/Technologies/chef_2.png"></li>
                            <li><img src="../../assets/img/Technologies/circle_0.png"></li>
                            <li><img src="../../assets/img/Technologies/kubernets_0.png"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/keras_2.png"></li>
                            <li><img src="../../assets/img/Technologies/python_1.png"></li>
                            <li><img src="../../assets/img/Technologies/android_0.png"></li>

                            <li><img src="../../assets/img/Technologies/pytorch_2.png"></li>
                            <li><img src="../../assets/img/Technologies/r_0.png"></li>
                            <li><img src="../../assets/img/Technologies/spark_2.png"></li>
                            <li><img src="../../assets/img/Technologies/tensorflow_0.png"></li>

                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Data Science</h2>
                        <p>Our Data Science capabilities help you predict business outcomes better, faster and more
                            efficiently</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Data Science</li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Data Engineering</h2>
                        <p>Data is the key to effective decision making. Uncover meaningful and actionable insights from
                            a huge amount of data.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Data Engineering</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/hadoop.png"></li>
                            <li><img src="../../assets/img/Technologies/highcharts.png"></li>
                            <li><img src="../../assets/img/Technologies/kafka.png"></li>
                            <li><img src="../../assets/img/Technologies/power-bi.png"></li>
                            <li><img src="../../assets/img/Technologies/tabelau.png"></li>
                            <li><img src="../../assets/img/Technologies/spark_2.png"></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/26.png"></li>
                            <li><img src="../../assets/img/Technologies/appium.png"></li>
                            <li><img src="../../assets/img/Technologies/seeted.png"></li>
                            <li><img src="../../assets/img/Technologies/selelium.png"></li>


                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Testing</h2>
                        <p>Zero-bug application is a distant dream but our automation and manual testing experts help
                            you achieve it.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Manual</li>
                            <li>Automation</li>
                            <li>Mobile</li>
                            <li>Independent</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Web Content Management</h2>
                        <p>We deliver personalised and contextual experience to create superior digital experience.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Drupal</li>
                            <li>AEM</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li style="width: auto;"><img src="../../assets/img/Technologies/30.png"></li>
                            <li><img src="../../assets/img/Technologies/aem.png"></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/cassandra_2.png"></li>
                            <li><img src="../../assets/img/Technologies/elasticsearch_1.png"></li>
                            <li><img src="../../assets/img/Technologies/mongo-db_0.png"></li>
                            <li><img src="../../assets/img/Technologies/oracle_4.png"></li>
                            <li><img src="../../assets/img/Technologies/postgre-sql.png"></li>
                            <li><img src="../../assets/img/Technologies/sql-server.png"></li>

                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Databases</h2>
                        <p> We help enterprises to access & manage data across different sources, seamlessly integrating
                            on-premises and cloud environments to extract actionable insights.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Manual</li>
                            <li>Automation</li>
                            <li>Mobile</li>
                            <li>Independent</li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <div class="chart mobTexh">
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Backend Engineering</h2>
                        <p>We use Java and JavaScript based frameworks for server-side development. We use relational as
                            well as NoSQL databases for faster processing of complex set of data.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Java</li>
                            <li>Node.js</li>
                            <li>MEAN</li>
                            <li>Python</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/java_0.png"></li>
                            <li><img src="../../assets/img/Technologies/mean-stack.png"></li>
                            <li><img src="../../assets/img/Technologies/mongo-db_0.png"></li>
                            <li><img src="../../assets/img/Technologies/my-sql.png"></li>
                            <li><img src="../../assets/img/Technologies/net.png"></li>
                            <li><img src="../../assets/img/Technologies/nod.png"></li>
                            <li><img src="../../assets/img/Technologies/pytons.png"></li>
                            <li><img src="../../assets/img/Technologies/spring_3.png"></li>
                        </ul>
                    </div>
                </div>
          
            </div>

            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Frontend Engineering</h2>
                        <p>JavaScript in combination with HTML and CSS has revolutionised the way web and mobile
                            applications are built and we are extremely adept at implementing the JS universe.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>AngularJs</li>
                            <li>React Native</li>
                            <li>React js</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/9.png"></li>
                            <li><img src="../../assets/img/Technologies/10.png"></li>
                            <li><img src="../../assets/img/Technologies/11.png"></li>

                            <li><img src="../../assets/img/Technologies/Bootstrap.png"></li>
                            <li><img src="../../assets/img/Technologies/Polymar.png"></li>
                            <li><img src="../../assets/img/Technologies/vue.png" style="margin-top: 10px;"></li>

                        </ul>
                    </div>
                </div>


            </div>
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Frameworks</h2>
                        <p>Frameworks to design & support development of Platform, Software & Web Applications with
                            faster delivery & consistency.</p>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/blaze_0.png"></li>
                            <li><img src="../../assets/img/Technologies/djamgo_0.png"></li>
                            <li><img src="../../assets/img/Technologies/flask_1.png"></li>
                            <li><img src="../../assets/img/Technologies/react_0.png"></li>
                            <li><img src="../../assets/img/Technologies/spring_3.png"></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Mobility</h2>
                        <p>Experts in developing experiential applications using native as well as hybrid technologies
                            for mobile, tablets and connected devices.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>iOS</li>
                            <li>Android</li>
                            <li>React js</li>
                            <li>Hybrid</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/13.png"></li>
                            <li><img src="../../assets/img/Technologies/16.png"></li>
                            <li><img src="../../assets/img/Technologies/android_0.png"></li>

                            <li><img src="../../assets/img/Technologies/android-tv.png"></li>
                            <li><img src="../../assets/img/Technologies/flask_1.png"></li>
                            <li><img src="../../assets/img/Technologies/hisense_0.png"></li>
                            <li><img src="../../assets/img/Technologies/lg.png"></li>
                            <li><img src="../../assets/img/Technologies/netrange_1.png"></li>
                            <li><img src="../../assets/img/Technologies/roku_0.png"></li>
                            <li><img src="../../assets/img/Technologies/samsung_1.png"></li>

                        </ul>
                    </div>
                </div>

            </div>
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Cloud & DevOps</h2>
                        <p>We help customers migrate to Cloud and provide on-going DevOps automation & 24*7 incident
                            management services.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>DevOps on AWS</li>
                            <li>DevOps Tools</li>
                            <li>AWS Migration</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/Group 341.png"></li>
                            <li><img src="../../assets/img/Technologies/ansible_0.png"></li>
                            <li><img src="../../assets/img/Technologies/aws_0.png"></li>
                            <li><img src="../../assets/img/Technologies/azure_0.png"></li>
                            <li><img src="../../assets/img/Technologies/Group 342.png"></li>
                            <li><img src="../../assets/img/Technologies/docker_2.png"></li>
                            <li><img src="../../assets/img/Technologies/google-cloud_0.png"></li>
                            <li><img src="../../assets/img/Technologies/jenkins_0.png"></li>
                            <li><img src="../../assets/img/Technologies/Group 343.png"></li>
                            <li><img src="../../assets/img/Technologies/chef_2.png"></li>
                            <li><img src="../../assets/img/Technologies/circle_0.png"></li>
                            <li><img src="../../assets/img/Technologies/kubernets_0.png"></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Data Science</h2>
                        <p>Our Data Science capabilities help you predict business outcomes better, faster and more
                            efficiently</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Data Science</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/keras_2.png"></li>
                            <li><img src="../../assets/img/Technologies/python_1.png"></li>
                            <li><img src="../../assets/img/Technologies/android_0.png"></li>

                            <li><img src="../../assets/img/Technologies/pytorch_2.png"></li>
                            <li><img src="../../assets/img/Technologies/r_0.png"></li>
                            <li><img src="../../assets/img/Technologies/spark_2.png"></li>
                            <li><img src="../../assets/img/Technologies/tensorflow_0.png"></li>

                        </ul>
                    </div>
                </div>

            </div>

            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Data Engineering</h2>
                        <p>Data is the key to effective decision making. Uncover meaningful and actionable insights from
                            a huge amount of data.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Data Engineering</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/hadoop.png"></li>
                            <li><img src="../../assets/img/Technologies/kafka.png"></li>
                            <li><img src="../../assets/img/Technologies/power-bi.png"></li>
                            <li><img src="../../assets/img/Technologies/spark_2.png"></li>

                        </ul>
                    </div>
                </div>
            </div>
            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Testing</h2>
                        <p>Zero-bug application is a distant dream but our automation and manual testing experts help
                            you achieve it.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Manual</li>
                            <li>Automation</li>
                            <li>Mobile</li>
                            <li>Independent</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/26.png"></li>
                            <li><img src="../../assets/img/Technologies/appium.png"></li>
                            <li><img src="../../assets/img/Technologies/seeted.png"></li>
                            <li><img src="../../assets/img/Technologies/selelium.png"></li>


                        </ul>
                    </div>
                </div>


            </div>
            <div class="textCard">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Web Content Management</h2>
                        <p>We deliver personalised and contextual experience to create superior digital experience.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Drupal</li>
                            <li>AEM</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li style="width: auto;"><img src="../../assets/img/Technologies/30.png"></li>
                            <li><img src="../../assets/img/Technologies/aem.png"></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="textCard" style="background: #EDF0F7 0% 0% no-repeat padding-box;">
                <div class="col-sm-6">
                    <div class="innerCard">
                        <h2>Databases</h2>
                        <p> We help enterprises to access & manage data across different sources, seamlessly integrating
                            on-premises and cloud environments to extract actionable insights.</p>
                        <span>Our Offerings</span>
                        <ul class="iconsS">
                            <li>Manual</li>
                            <li>Automation</li>
                            <li>Mobile</li>
                            <li>Independent</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="innerCard">
                        <ul class="logoTX">
                            <li><img src="../../assets/img/Technologies/cassandra_2.png"></li>
                            <li><img src="../../assets/img/Technologies/elasticsearch_1.png"></li>
                            <li><img src="../../assets/img/Technologies/mongo-db_0.png"></li>
                            <li><img src="../../assets/img/Technologies/oracle_4.png"></li>
                            <li><img src="../../assets/img/Technologies/postgre-sql.png"></li>
                            <li><img src="../../assets/img/Technologies/sql-server.png"></li>

                        </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a href="">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>