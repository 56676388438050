<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Careers </h2>
                <!-- <p>We have an enviable list of happy customers</p> -->
            </div>
        </div>
    </div>
    <div class="container">
        <div class="carrireTopInner">
            <h2>Let’s build great things together!</h2>
            <p>At Patch infotech we believe that our key to success is people – both who we employ and who we choose to
                collaborate with. We do care to bring out the best in every team member. We want your ideas to be heard
                and let you change this world. That’s why we are always looking for out of box thinkers, limit pushers
                and passionate dreamers.</p>
            <p>Are you ready to solve interesting and challenging issues? Do you want to create entirely new innovative
                products? Then join our team of innovators!</p>
        </div>
    </div>
    <div class="container">
        <div class="carrireTopInner">
            <h2>Open positions</h2>
            <p>Let’s write some beautiful code and make smart designs. This is what we’re crazy about, and we’re looking
                for the ones who feel the same.</p>
        </div>

        <div class="openPossisions">
            <div class="col-sm-9">
                <div class="interPossisonsTab">
                    <span>Senior Java Developer</span>
                    <div class="iconPossision">
                        <ul>
                            <li><img src="../../assets/img/Career/Group 495.png" alt=""> 3-4 Experience</li>
                            <li><img src="../../assets/img/Career/Group 494.png" alt=""> Gurgaon</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="iconPossision">
                    <a href="" class="aply">Apply Now</a>
                </div>

            </div>
        </div>
        <div class="openPossisions">
            <div class="col-sm-9">
                <div class="interPossisonsTab">
                    <span>Senior Front End Developer</span>
                    <div class="iconPossision">
                        <ul>
                            <li><img src="../../assets/img/Career/Group 495.png" alt=""> 3-4 Experience</li>
                            <li><img src="../../assets/img/Career/Group 494.png" alt=""> Gurgaon</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="iconPossision">
                    <a href="" class="aply">Apply Now</a>
                </div>

            </div>
        </div>
        <div class="openPossisions">
            <div class="col-sm-9">
                <div class="interPossisonsTab">
                    <span>Senior Backend Developer</span>
                    <div class="iconPossision">
                        <ul>
                            <li><img src="../../assets/img/Career/Group 495.png" alt=""> 3-4 Experience</li>
                            <li><img src="../../assets/img/Career/Group 494.png" alt=""> Gurgaon</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <div class="iconPossision">
                    <a href="" class="aply">Apply Now</a>
                </div>

            </div>
        </div>
        <div class="see" style="margin: 40px 0px;">
            <a href="" class="seeMore">See More</a>
        </div>
    </div>
    <div class="container-flued">
        <div class="sendYourResum">
            <h2>Excited to work with us… <br> But don’t see your positions listed</h2>
            <a href="">Send Your Resume</a>
        </div>
    </div>
    <div class="container">
        <div class="corporateValues">
            <div class="topCorporateValue">
                <h2>Corporate values</h2>
                <p>We are your easiest solution ever.</p>
            </div>
            <div class="corporteCard">
                <div class="col-sm-4">
                    <div class="corporteCardInner">
                        <img src="../../assets/img/Career/Group 500.png">
                        <p>Transparency</p>
                    </div>

                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="corporteCardInner">
                        <img src="../../assets/img/Career/Group 501.png">
                        <p>Innovativeness</p>
                    </div>

                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="corporteCardInner">
                        <img src="../../assets/img/Career/Group 502.png">
                        <p>Client-thinking</p>
                    </div>

                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="corporteCardInner">
                        <img src="../../assets/img/Career/Group 500.png">
                        <p>Result-orientedness</p>
                    </div>

                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="corporteCardInner">
                        <img src="../../assets/img/Career/Group 504.png">
                        <p>Reliability</p>
                    </div>

                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="corporteCardInner">
                        <img src="../../assets/img/Career/Group 503.png">
                        <p>Effectiveness</p>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="serviceSection" style="width:100%; float:left; opacity: 1; padding:20px 0px;">
            <div class="headingHome">
                <h2>We offer</h2>
            </div>

            <div class="whoWeAre">
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/Career/Group 510.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Challenging projects</h2>
                            <p>We use innovative technologies</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/Career/Group 513.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>International experience</h2>
                            <p>A chance to work with international projects</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/Career/Group 511.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Great team</h2>
                            <p>A chance to work with international projects</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/Career/Group 512.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Comfortable office</h2>
                            <p>Modern cozy office facilities with friendly atmosphere and needed environment.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>