<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
        <div class="textTopBannerHomePage">
            <h2>About Us </h2>
           
        </div>
        </div>
    </div>
    <div class="container">
        <div class="aboutTopWrap">
            <div class="topTopWrap">
                <h2>Innovative. Challenging. Reliable.</h2>
                <p>Being a Design-First company, IT IS IN OUR DNA TO MAKE PRODUCTS THAT LOOK THE BEST AND WORK EVEN BETTER. We are specializing in mobile and web development, design creation, DevOps development solutions and Staff Augmentation. We do believe in approaching every project no matter how creative your idea is. Combining our tech expertise with your business needs we ensure that your business will have a profitable competitive advantage.</p>
            </div>

            <ul class="listTop">
                <div class="col-sm-3 fstHomeTop" style="padding-left: 0px;">
                <li>
                    <h2>20 <span>+</span></h2> <p>End To End Projects <br> Developed</p>
                </li>
                </div>
                 <div class="col-sm-3 fstHomeTop">
                <li>
                    <h2>20 <span>+</span></h2> <p>Web & CRM Panels <br> Developed</p>
                </li>
                </div>
                 <div class="col-sm-3 fstHomeTop">
                <li>
                    <h2>25 <span>+</span></h2> <p>Startups Served</p>
                </li>
                </div>
                 <div class="col-sm-3 fstHomeTop">
                <li>
                    <h2>45 <span>+</span></h2> <p> Clients Served</p>
                </li>
                </div>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="serviceSection" style="padding:20px 0px; width: 100%; float: left; margin-bottom: 10px;">
            <div class="headingHome">
                <h2>Who we are?</h2>
            </div>
            <div class="textWrap">
                <p>We are your easiest solution ever.</p>
            </div>
            <div class="whoWeAre">
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 484.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Innovation inspired</h2>
                            <p>We hold a hand on a pulse of the newest development tendencies in the digital world. At our disposal, we have various up-to-date tools at our disposal to stay on top and provide our customers with the most innovative solutions.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 482.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Open minded</h2>
                            <p>We are the team out-of-the-box thinkers. We are searching for challenging ideas to show how to make the impossible become possible and even real.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 483.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Technology focused</h2>
                            <p>High tech tools and new technologies are the synonyms to successful business processes. We believe that providing innovative technologies to your business makes your service unique, competitive and profitable.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 481.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Client and result oriented</h2>
                            <p>We always value your ideas and your business needs. Explore your ideas in the most innovative way and implement it as your best business practice. Your success is the best reward for us.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued">
        <div class="serviceSection" style="width:100%; float:left; margin-bottom: 10px; background: #EDF0F7 0% 0% no-repeat padding-box;
        opacity: 1; padding:20px 0px !important;">
        <div class="container">
            <div class="headingHome">
                <h2>We offer</h2>
            </div>

            <div class="whoWeAre">
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 477.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Design</h2>
                            <p>We hold a hand on a pulse of the newest development tendencies in the digital world. At our disposal, we have various up-to-date tools at our disposal to stay on top and provide our customers with the most innovative solutions.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 480.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>App development</h2>
                            <p>We are the team out-of-the-box thinkers. We are searching for challenging ideas to show how to make the impossible become possible and even real.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 478.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>Web development</h2>
                            <p>High tech tools and new technologies are the synonyms to successful business processes. We believe that providing innovative technologies to your business makes your service unique, competitive and profitable.</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 fstHomeTop">
                    <div class="whoWeAreIner">
                        <div class="logoWhoWeAre">
                            <img src="../../assets/img/About Us-1/Group 479.png">
                        </div>
                        <div class="logoWhoWeContent">
                            <h2>DevOps</h2>
                            <p>Our DevOps team works closely with the development team to automate end to end delivery pipeline.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="container">
        <div class="serviceSection">
            <div class="headingHome">
                <h2>Why Choose us?</h2>
            </div>
            <div class="textWrap">
                <span>We work selflessly “To serve established and emerging organisations with the best IT services to
                    help them achieve their ultimate goals.”</span>
                <p>We design meaningful digital products & experiences for people. We leave no stone unturned while
                    working so that we can deliver our clients the best. It makes us feel highly proud and encouraged
                    when our clients appreciate our work.</p>
            </div>
            <div class="threeDivWrap">
                <div class="col-sm-4 fstHomeTop" style="padding-left:0px;">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 358.png" alt="">
                        </div>
                        <h2 class="subHeading">Excellence is our habit</h2>
                        <p>We settle for nothing less! We create designs and code to inspire and attract.</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 237.png" alt="">
                        </div>
                        <h2 class="subHeading">Our Flexibility</h2>
                        <p>Flexibility allows us to provide our clients with a method that truly suits their individual
                            needs.</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 236.png" alt="">
                        </div>
                        <h2 class="subHeading">Quality of our services</h2>
                        <p>With 35+ Developers & Designers team we maintained our delivery quality to such an extent
                            that our successful deployment of projects and resources is above 95%.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
        <div class="bottomLast">
            <div class="col-sm-8">
            <div class="firstBottomText">
               <h2>Interested To Get Our Featured Service?</h2>
            </div>
            </div>
            <div class="col-sm-4">
               <div class="firstBottomText">
                  <a [routerLink]="['/contact']">Connect with Us</a>
               </div>
               </div>
            </div>
        </div>
        </div>
</div>
