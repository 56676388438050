<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Featured Projects </h2>
                <p>Our Successful projects</p>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="serviceSection">
            <div class="featuredProject">
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/boost']">
                            <img src="../../assets/img/Home page/Group 643/Group 643.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/gromo']">
                            <img src="../../assets/img/Home page/Group 631.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/chqbook']">
                            <img src="../../assets/img/Home page/Group 634.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/irctc']">
                            <img src="../../assets/img/Home page/Group 642.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/dubai']">
                            <img src="../../assets/img/Home page/Group 641.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/beatroute']">
                            <img src="../../assets/img/Home page/Group 630.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/fit']">
                            <img src="../../assets/img/Featured Projects/Group 757.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/sws']">
                            <img src="../../assets/img/Featured Projects/Group 764.png">
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="imgProduct">
                        <a [routerLink]="['/relocatte']">
                            <img src="../../assets/img/Featured Projects/Group 640.png">
                        </a>
                    </div>
                </div>

                <div class="button btnProduct">
                    <a href="">Laod More</a>
                </div>

            </div>
        </div>

    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>