import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chqbook',
  templateUrl: './chqbook.component.html',
  styleUrls: ['./chqbook.component.scss']
})
export class ChqbookComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
