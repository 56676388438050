import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
) { }


  ngOnInit(): void {
    const script = this.renderer2.createElement('script');
    script.text = `
    gtag('event', 'conversion', {'send_to': 'AW-10947204599/QZuPCO3kxc8DEPerhOQo'});
    console.log("Running.... gtag('event', 'conversion', {'send_to': 'AW-10947204599/QZuPCO3kxc8DEPerhOQo'});")
    fbq('track', 'Lead');
    `;

    this.renderer2.appendChild(this.document.body, script);
  }

}
