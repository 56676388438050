<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Contact Us </h2>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="topForm">


            <div class="col-sm-4">
                <div class="tabWrap" id="exTab1">
                    <ul class="nav nav-pills">
                        <li class="active">
                            <a href="#1a" data-toggle="tab" style="font-size: 20px;">Request for services</a>
                        </li>
                        <li style="margin-top: 10px;">
                            <a href="#2a" data-toggle="tab" style="font-size: 20px;">Join our team</a></li>
                    </ul>

                </div>
            </div>

            <div class="col-sm-8 tab-content clearfix fstHomeTop">
                <div class="formWra first tab-pane active" id="1a">
                    <h2>We would love to hear from you</h2>
                    <form class="shake" role="form" method="post" id="contactForm" name="contact-form"
                        data-toggle="validator">
                        <!-- Name -->
                        <div class="form-group label-floating" style="margin-right: 10px;">
                            <input class="form-control" id="name" type="text" placeholder="First Name" name="name"
                                required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="Last Name*" name="name"
                                required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <!-- email -->
                        <div class="form-group control label-floating">
                            <input class="form-control control" id="email" placeholder="Business Email*" type="email"
                                name="email" required data-error="Please enter your Email">
                            <div class="help-block with-errors"></div>
                        </div>
                        <!-- Subject -->
                        <div class="form-group control label-floating">
                            <input class="form-control control" id="msg_subject" placeholder="Business Email*"
                                type="text" name="subject" required data-error="Please enter your message subject">
                            <div class="help-block with-errors"></div>
                        </div>
                        <!-- Message -->
                        <div class="form-group control label-floating">
                            <input class="form-control control" placeholder="Business Email*" id="msg_subject"
                                type="text" name="subject" required data-error="Please enter your message subject">
                        </div>
                        <!-- Form Submit -->
                        <div class="form-submit mt-5" style="display: block; text-align: center; 
                            font: normal normal 600 16px Spartan !important;
                            letter-spacing: 0px;
                            color: #4A78EB;
                            opacity: 1;
height: 76px;">
                            <button class="btn btn-common"
                                style="display: inline-block; font-size: 16px; margin-top: 20px;" type="submit"
                                id="form-submit"><i class="material-icons mdi mdi-message-outline"></i> Talk to our
                                Solution Experts</button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                        </div>
                    </form>
                </div>

                <div class="formWra second tab-pane" id="2a">
                    <h2>Be a part of our high performing team</h2>
                    <form class="shake" role="form" method="post" id="contactForm" name="contact-form"
                        data-toggle="validator">
                        <!-- Name -->
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="First Name" name="name"
                                required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="Last Name*" name="name"
                                required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="Phone*" name="name" required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="Experience*" name="name"
                                required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="Skill*" name="name" required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating">
                            <input class="form-control" id="name" type="text" placeholder="Location*" name="name"
                                required>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group label-floating" style="border-bottom: 1px solid #ccc;">
                            <input type="file" class="custom-file-input" id="inputGroupFile02">
                            <label class="custom-file-label" for="inputGroupFile02"
                                style="color: #000 !important;">Choose
                                file</label>
                            <div class="help-block with-errors"></div>
                        </div>

                        <!-- Form Submit -->
                        <div class="form-submit mt-5" style="display: block; text-align: center; 
                        font: normal normal 600 16px Spartan !important;
                        letter-spacing: 0px;
                        color: #4A78EB;
                        opacity: 1;
height: 76px;">
                            <button class="btn btn-common"
                                style="display: inline-block; font-size: 16px; margin-top: 20px;" type="submit"
                                id="form-submit"><i class="material-icons mdi mdi-message-outline"></i> Submit
                                Now</button>
                            <div id="msgSubmit" class="h3 text-center hidden"></div>
                            <div class="clearfix"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <h2 class="contactHeading">Explore your ideas with us!</h2>
        <div class="contactMiddle">
            <div class="col-sm-4">
                <div class="innterTree">
                    <img src="../../assets/img/Contact Us/Group 554.png" class="textTab">
                    <p style="margin-top: 9px;"> Nitin Bansal</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="innterTree">
                    <img src="../../assets/img/Contact Us/Group 555.png" class="textTab">
                    <p style="margin-top: 9px;"> 91-9654854895</p>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="innterTree">
                    <img src="../../assets/img/Contact Us/Group 556.png" class="textTab">
                    <p> 91springboard, Building Number 145, Sector 44 Rd, Sector 44, Gurgaon - 122003</p>
                </div>
            </div>
        </div>
    </div>

    <div class="container-flued">
        <div class="innnerSendMail">
            <div class="container">
                <div cliss="iconLat">
                    <img src="../../assets/img/Contact Us/Group 523.png">
                </div>
                <h2>Write to Us</h2>
                <div class="email">
                    <a href="">contactus@patchinfotech.co.in</a>
                </div>
            </div>
        </div>
    </div>

</div>