import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

declare global {
  interface Window {
    lintrk: any;
  }
}
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit {

  constructor(public title: Title) { }

  ngOnInit(): void {
    this.title.setTitle(`India's Leading IT Staff Augmentation Enterprise`);
  }

  onCTAClick = () => {
    window.lintrk('track', { conversion_id: 9712409 });
  }

}
