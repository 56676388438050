import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ClientComponent } from './client/client.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { FeaturedProductComponent } from './featured-product/featured-product.component';
import { IndustriesComponent } from './industries/industries.component';
import { TechnologyComponent } from './technology/technology.component';
import { CarrierComponent } from './carrier/carrier.component';
import { ContactComponent } from './contact/contact.component';
import { BoostComponent } from './projects/boost/boost.component';
import { GromoComponent } from './projects/gromo/gromo.component';
import { ChqbookComponent } from './projects/chqbook/chqbook.component';
import { IrctcComponent } from './projects/irctc/irctc.component';
import { DubaiComponent } from './projects/dubai/dubai.component';
import { BeatrouteComponent } from './projects/beatroute/beatroute.component';
import { FitComponent } from './projects/fit/fit.component';
import { RelocattComponent } from './projects/relocatt/relocatt.component';
import { DswscComponent } from './projects/dswsc/dswsc.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ClientComponent,
    ServicesComponent,
    AboutComponent,
    FeaturedProductComponent,
    IndustriesComponent,
    TechnologyComponent,
    CarrierComponent,
    ContactComponent,
    BoostComponent,
    GromoComponent,
    ChqbookComponent,
    IrctcComponent,
    DubaiComponent,
    BeatrouteComponent,
    FitComponent,
    RelocattComponent,
    DswscComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
