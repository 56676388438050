import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gromo',
  templateUrl: './gromo.component.html',
  styleUrls: ['./gromo.component.scss']
})
export class GromoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
