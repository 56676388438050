<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>irctc.com </h2>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="boostMainWrap">
            <div class="col-sm-5">
                <div class="boostImageWrap" style="height: auto;">
                    <img src="../../../assets/img/irctc.com/Group 737.png">
                </div>
            </div>
            <div class="col-sm-7">
                <div class="rightPROduct">
                    <div class="noLogo">
                        <img src="../../../assets/img/irctc.com/Group 711.png" alt="">
                    </div>
                    <div class="noLogo">
                        <img src="../../../assets/img/irctc.com/irctc.com.png" alt="">
                    </div>
                    <div class="textProductRight">
                        <p>Indian Railway Catering And Tourism <br>
                            Corporation Limited</p>
                    </div>
                    <div class="rightBottom">
                        <div class="col-sm-6" style="padding-left:0px;">
                            <p class="bottomProductLast">Website Link - <a
                                    href="https://www.rr.irctctourism.com">https://www.rr.irctctourism.com</a></p>
                        </div>
                        <div class="col-sm-6" style="padding-left:0px; margin-top: 10px;">
                            <p class="bottomProductLast"> Available in - <img
                                    src="../../../assets/img/irctc.com/Rectangle 279.png"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boostMainWrap thisRight">
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/irctc.com/Our Porfolio-11 2.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/irctc.com/Our Porfolio-11 3.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/irctc.com/Our Porfolio-11.png">
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>