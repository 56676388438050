import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ClientComponent } from './client/client.component';
import { ServicesComponent } from './services/services.component';
import { AboutComponent } from './about/about.component';
import { FeaturedProductComponent } from './featured-product/featured-product.component';
import { IndustriesComponent } from './industries/industries.component';
import { TechnologyComponent } from './technology/technology.component';
import { CarrierComponent } from './carrier/carrier.component';
import { ContactComponent } from './contact/contact.component';
import { BoostComponent } from './projects/boost/boost.component';
import { BeatrouteComponent } from './projects/beatroute/beatroute.component';
import { ChqbookComponent } from './projects/chqbook/chqbook.component';
import { DubaiComponent } from './projects/dubai/dubai.component';
import { GromoComponent } from './projects/gromo/gromo.component';
import { IrctcComponent } from './projects/irctc/irctc.component';
import { RelocattComponent } from './projects/relocatt/relocatt.component';
import { FitComponent } from './projects/fit/fit.component';
import { DswscComponent } from './projects/dswsc/dswsc.component';
import {LayoutModule } from './layout/layout.module';
import { LandingComponent } from './landing/landing.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BlogComponent } from './blog/blog.component';
import { BlogInnerComponent } from './blog-inner/blog-inner.component';

const routes: Routes = [

  { path: '', loadChildren: './layout/layout.module#LayoutModule' },
/*   { path: 'client', component:ClientComponent },
  { path: 'services', component:ServicesComponent },
  { path: 'about', component:AboutComponent },
  { path: 'featured-products', component:FeaturedProductComponent },
  { path: 'industries', component:IndustriesComponent },
  { path: 'technology', component:TechnologyComponent },
  { path: 'career', component:CarrierComponent },
  { path: 'contact', component:ContactComponent },
  { path: 'boost', component:BoostComponent },
  { path: 'beatroute', component:BeatrouteComponent },
  { path: 'chqbook', component:ChqbookComponent },
  { path: 'dubai', component:DubaiComponent },
  { path: 'gromo', component:GromoComponent },
  { path: 'irctc', component:IrctcComponent },
  { path: 'relocatte', component:RelocattComponent },
  { path: 'fit', component:FitComponent },
  { path: 'sws', component:DswscComponent }, */
  { path: 'staff-augmentation', component: LandingComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'blog-inner', component: BlogInnerComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
