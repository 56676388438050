<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Boost nowfloats </h2>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="boostMainWrap">
            <div class="col-sm-5">
                <div class="boostImageWrap" style="height: auto;">
                    <img src="../../../assets/img/Boost nowfloats/Group 712.png">
                </div>
            </div>
            <div class="col-sm-7">
                <div class="rightPROduct">
                    <div class="noLogo">
                        <img src="../../../assets/img/Boost nowfloats/Group 711.png" alt="">
                    </div>
                    <div class="noLogo">
                        <img src="../../../assets/img/Boost nowfloats/Group 685.png" alt="">
                    </div>
                    <div class="textProductRight">
                        <p>One app to manage your digital business. Boost 360 is probably the world’s best online
                            business management suite and specifically it let’s you do three things for business online:
                            Setup, Manage, Grow.</p>
                    </div>
                    <div class="rightBottom">
                        <div class="col-sm-6" style="padding-left:0px;">
                            <p class="bottomProductLast">Website Link - <a
                                    href="https://boost.nowfloats.com">https://boost.nowfloats.com</a></p>
                        </div>
                        <div class="col-sm-6" style="padding-left:0px; margin-top: 10px;">
                            <p class="bottomProductLast"> Available in - <img
                                    src="../../../assets/img/Boost nowfloats/Rectangle 279.png"> <img
                                    src="../../../assets/img/Boost nowfloats/Rectangle 279.png"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boostMainWrap thisRight">
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Boost nowfloats/Group 678.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Boost nowfloats/Group 677.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Boost nowfloats/Group 676.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap" style="height:750px;">
                    <img src="../../../assets/img/Boost nowfloats/Group 672.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Boost nowfloats/Group 674.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Boost nowfloats/Group 674.png">
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>