<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Gromo </h2>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="boostMainWrap">
            <div class="col-sm-5">
                <div class="boostImageWrap" style="height: 455px;">
                    <img src="../../../assets/img/Gromo/Group 713.png">
                </div>
            </div>
            <div class="col-sm-7">
                <div class="rightPROduct">
                    <div class="noLogo">
                        <img src="../../../assets/img/Gromo/Group 709.png" alt="">
                    </div>
                    <div class="noLogo">
                        <img src="../../../assets/img/Gromo/Group 701.png" alt="">
                    </div>
                    <div class="textProductRight">
                        <p>Gromo platform helps you to start your entrepreneurial <br> journey. <br> Benefit with Gromo
                            is you can work from anywhere by referring <br> clients with requirement of loans and
                            insurance.</p>
                    </div>
                    <div class="rightBottom" style="margin-top: 62px;">
                        <div class="col-sm-6" style="padding-left:0px;">
                            <p class="bottomProductLast">Website Link - <a
                                    href="https://www.gromo.in/">https://www.gromo.in/</a></p>
                        </div>
                        <div class="col-sm-6" style="padding-left:0px; margin-top: 10px;">
                            <p class="bottomProductLast"> Available in - <img
                                    src="../../../assets/img/Gromo/Rectangle 279.png"> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boostMainWrap thisRight">
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Gromo/Rectangle 304.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Gromo/Rectangle 308.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Gromo/Rectangle 306.png">
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>