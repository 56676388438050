<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>BeatRoute </h2>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="boostMainWrap">
            <div class="col-sm-5">
                <div class="boostImageWrap" style="height: auto;">
                    <img src="../../../assets/img/BeatRoute/Group 754.png">
                </div>
            </div>
            <div class="col-sm-7">
                <div class="rightPROduct">
                    <div class="noLogo">
                        <img src="../../../assets/img/BeatRoute/Group 711.png" alt="">
                    </div>
                    <div class="noLogo">
                        <img src="../../../assets/img/BeatRoute/BeatRoute.png" alt="">
                    </div>
                    <div class="textProductRight">
                        <p>360 intelligent field force CRM platform for sales <br>representative visual Merchandisers
                        </p>
                    </div>
                    <div class="rightBottom" style="margin-top: 90px;">
                        <div class="col-sm-12" style="padding-left:0px;">
                            <p class="bottomProductLast">Website Link - <a
                                    href="https://beatroute.io/">https://beatroute.io/</a></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boostMainWrap thisRight">
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/BeatRoute/Our Porfolio-15 2.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/BeatRoute/Our Porfolio-15 2.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/BeatRoute/Our Porfolio-15.png">
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>