<!-- ======= Header ======= -->
<header id="header-thank">
    <div class="container d-flex align-items-center">

        <a href="/" class="logo mr-lg-auto"><img src="../../assets/img/landing/logo.png" alt=""></a>

        <nav class="nav-menu d-none">
            <ul>
                <li><a href="tel:+912235542771"><i class="fa fa-phone" aria-hidden="true"></i>+91 22-35542771</a></li>
                <li><a href="mailto:contactus@patchinfotech.com"><i class="fa fa-phone" aria-hidden="true"></i>contactus@patchinfotech.com</a></li>
            </ul>
        </nav>
        <!-- .nav-menu -->

    </div>
</header>
<!-- End Header -->

<main id="main">

    <section class="blog-inner-title">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="single-blog-main-title">
              <h2>4 Ways Digital Product Engineering can help you<br class="d-none d-md-block"> 
                accelerate Business Growth</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="blog-inner" id="blog-inner">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-12">
            <div class="social-details">
              <div>
                <p><img src="../../assets/img/Blog/blog-inner/calendar.png"> &nbsp; July 06, 2022</p>
              </div>
              <div class="link">
                <ul>
                  <li><img src="../../assets/img/Blog/blog-inner/linkedin.png"></li>
                  <li><img src="../../assets/img/Blog/blog-inner/twitter.png"></li>
                  <li><img src="../../assets/img/Blog/blog-inner/facebook.png"></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-12"></div>
          <div class="col-lg-9 col-md-12">
            <div class="blog-inner-main-image">
              <img src="../../assets/img/Blog/blog-inner/Blog 2_1.png">
            </div>
            <div class="description">
              <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <h2 class="mt-5">Why do we use it?</h2>
                <p class="mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of 
                  using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it 
                  look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search 
                  for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, 
                  sometimes on purpose (injected humour and the like).</p>
                  <h2 class="mt-5">Why do we use it?</h2>
                  <p class="mt-3">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of 
                    using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look 
                    like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 
                    'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes 
                    on purpose (injected humour and the like).</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-12">
            <div class="form">
              <form id="inquiryForm" method="post" role="form">
                <h3>Quick Inquiry</h3>
                <ul>
                  <li><div class="form-group">
                    <input type="text" class="form-control" name="name" placeholder="Name">
                  </div></li>
                  <li><div class="form-group">
                    <input type="text" class="form-control" name="email" placeholder="Email">
                  </div></li>
                  <li><div class="form-group">
                    <input type="text" class="form-control" name="number" placeholder="Phone Number">
                  </div></li>
                  <li><div class="form-group">
                    <textarea class="form-control" name="message" placeholder="Message"></textarea>
                  </div></li>
                  <li><div class="form-group mt-3">
                  <button type="submit" class="submit-btn">Submit</button>
                  </div></li>
                </ul>
              </form>
            </div>
            <div class="recent-post-main mt-5">
              <div class="Recent-title">
                <h3>Recent blogs</h3>
              </div>
              <ul class="recent-post">
                <li class="single-recent-post-list">
                  <div class="recent-post-box">
                    <a href="">
                      <div class="recent-post-image">
                        <img src="../../assets/img/Blog/blog-inner/Recent 1.png">
                      </div>
                      <div class="recent-post-content">
                        <h3 class="recent-post-title">printing and typesetting industry. Lorem Ipsum has been the</h3>
                      </div>
                    </a>
                  </div>
                </li>
                <li class="single-recent-post-list">
                  <div class="recent-post-box">
                    <a href="">
                      <div class="recent-post-image">
                        <img src="../../assets/img/Blog/blog-inner/recent 2.png">
                      </div>
                      <div class="recent-post-content">
                        <h3 class="recent-post-title">printing and typesetting industry. Lorem Ipsum has been the</h3>
                      </div>
                    </a>
                  </div>
                </li>
                <li class="single-recent-post-list">
                  <div class="recent-post-box">
                    <a href="">
                      <div class="recent-post-image">
                        <img src="../../assets/img/Blog/blog-inner/recent 3.png">
                      </div>
                      <div class="recent-post-content">
                        <h3 class="recent-post-title">printing and typesetting industry. Lorem Ipsum has been the</h3>
                      </div>
                    </a>
                  </div>
                </li>
                <li class="single-recent-post-list">
                  <div class="recent-post-box">
                    <a href="">
                      <div class="recent-post-image">
                        <img src="../../assets/img/Blog/blog-inner/recent 4.png">
                      </div>
                      <div class="recent-post-content">
                        <h3 class="recent-post-title">printing and typesetting industry. Lorem Ipsum has been the</h3>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
  </main>
  <!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer" class="d-none d-md-block d-lg-block">

    <div class="footer-top">
        <div class="container">
            <div class="row row-cols-md-5 no-gutters">
                <div class="col-6">
                    <div class="who-are-box">
                        <h3>Who are we</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="what-we-do-box">
                        <h3>What we do</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                            <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                            <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                            <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="contact-box">
                        <h3>Contact us</h3>
                        <ul>
                            <li>
                                <img src="../../assets/img/landing/call.png" class="img-fluid">
                                <a href="tel:9654854895">+91-9654854895</a>
                            </li>
                            <li>
                                <img src="../../assets/img/landing/email.png" class="img-fluid">
                                <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="address-box">
                         <h3>Address</h3>
                         <p>91springboard, Building<br>
                            Number 145,Sector 44Rd,<br>
                            Sector 44,Gurgaon-122003 
                         </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-logo">
                        <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                        <p>&#169;2020 Patch Infotech</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                        </div>
                        <div class="col-md-6">
                            <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

 <footer id="footer" class="d-block d-md-none d-lg-none">

    <div class="footer-top">
        <div class="container">
            <div class="row row-cols-md-5 no-gutters">
                <div class="col-6">
                    <div class="who-are-box">
                        <h3>Who are we</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="what-we-do-box">
                        <h3>What we do</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                            <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                            <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                            <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="contact-box">
                        <h3>Contact us</h3>
                        <ul>
                            <li>
                                <img src="../../assets/img/landing/call.png" class="img-fluid">
                                <a href="tel:9654854895">+91-9654854895</a>
                            </li>
                            <li>
                                <img src="../../assets/img/landing/email.png" class="img-fluid">
                                <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="address-box">
                         <h3>Address</h3>
                         <p>91springboard, Building<br>
                            Number 145,Sector 44Rd,<br>
                            Sector 44,Gurgaon-122003 
                         </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-logo">
                        <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                    </div>
                    <div class="social-links mt-4">
                        <a href="https://www.facebook.com/Patch-Infotech-111521311585621" target="_blank"><img src="../../assets/img/landing/fb.png" class="img-fluid"></a>
                        <a href="https://instagram.com/patchinfotech?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/img/landing/instagram.png" class="img-fluid"></a>
                        <a href="https://www.linkedin.com/company/14373720/admin/" target="_blank"><img src="../../assets/img/landing/linkdin.png" class="img-fluid"></a>
                        <a href="https://twitter.com/patch_infotech" target="_blank"><img src="../../assets/img/landing/tweet.png" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                        </div>
                        <div class="col-md-6">
                            <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->
<div id="preloader"></div>
