<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Clientele </h2>
                <p>We have an enviable list of happy customers</p>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="technology clintwrap desk" style="padding-top:0px;">
            <p class="clintRopPara">“Customer service is everything and anything that touches a customer – directly or
                indirectly. Customer service means servicing customers, and it’s so much more than just solving problems
                or addressing complaints.”</p>
            <div class="clintList">
                <ul>
                    <li><img src="../../assets/img/Home page/Group 133.png"></li>
                    <li><img src="../../assets/img/Home page/Group 134.png"></li>
                    <li><img src="../../assets/img/Home page/Group 135.png"></li>
                    <li><img src="../../assets/img/Home page/Group 136.png"></li>
                    <li><img src="../../assets/img/Home page/Group 137.png"></li>
                </ul>
                <ul>
                    <li><img src="../../assets/img/Home page/Group 138.png"></li>
                    <li><img src="../../assets/img/Home page/Group 139.png"></li>
                    <li><img src="../../assets/img/Home page/Group 140.png"></li>
                    <li><img src="../../assets/img/Home page/Group 141.png"></li>
                    <li><img src="../../assets/img/Home page/Group 142.png"></li>
                </ul>
                <ul>
                    <li><img src="../../assets/img/Home page/Group 143.png"></li>
                    <li><img src="../../assets/img/Home page/Group 144.png"></li>
                    <li><img src="../../assets/img/Home page/Group 145.png"></li>
                    <li><img src="../../assets/img/Home page/Group 146.png"></li>
                    <li><img src="../../assets/img/Home page/Group 147.png"></li>
                </ul>
                <ul>
                    <li><img src="../../assets/img/Home page/Group 148.png"></li>
                    <li><img src="../../assets/img/Home page/Group 149.png"></li>
                    <li><img src="../../assets/img/Home page/Group 150.png"></li>
                    <li><img src="../../assets/img/Home page/Group 151.png"></li>
                    <li><img src="../../assets/img/Home page/Group 152.png"></li>
                </ul>
            </div>
        </div>
        <div class="technology clintwrap mob" style="padding-top:0px;">
            <p class="clintRopPara">“Customer service is everything and anything that touches a customer – directly or
                indirectly. Customer service means servicing customers, and it’s so much more than just solving problems
                or addressing complaints.”</p>
            <div class="clintList">
                <ul>
                    <li><img src="../../assets/img/Home page/Group 133.png"></li>
                    <li><img src="../../assets/img/Home page/Group 134.png"></li>
                    <li><img src="../../assets/img/Home page/Group 135.png"></li>
                    <li><img src="../../assets/img/Home page/Group 136.png"></li>
                </ul>
                <ul>
                    <li><img src="../../assets/img/Home page/Group 137.png"></li>
                    <li><img src="../../assets/img/Home page/Group 138.png"></li>
                    <li><img src="../../assets/img/Home page/Group 139.png"></li>
                    <li><img src="../../assets/img/Home page/Group 140.png"></li>
                </ul>
                <ul>
                    <li><img src="../../assets/img/Home page/Group 141.png"></li>
                    <li><img src="../../assets/img/Home page/Group 142.png"></li>
                    <li><img src="../../assets/img/Home page/Group 143.png"></li>
                    <li><img src="../../assets/img/Home page/Group 144.png"></li>

                </ul>
                <ul>
                    <li><img src="../../assets/img/Home page/Group 145.png"></li>
                    <li><img src="../../assets/img/Home page/Group 146.png"></li>
                    <li><img src="../../assets/img/Home page/Group 147.png"></li>
                    <li><img src="../../assets/img/Home page/Group 148.png"></li>

                </ul>

                <ul>
                    <li><img src="../../assets/img/Home page/Group 149.png"></li>
                    <li><img src="../../assets/img/Home page/Group 150.png"></li>
                    <li><img src="../../assets/img/Home page/Group 151.png"></li>
                    <li><img src="../../assets/img/Home page/Group 152.png"></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>