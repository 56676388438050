


    <!-- ======= Header ======= -->
    <header id="header">
        <div class="container d-flex align-items-center">

            <a href="index.html" class="logo mr-lg-auto"><img src="../../assets/img/landing/logo.png" alt=""></a>

            <nav class="nav-menu">
                <ul>
                    <li><a href="tel:+912235542771"><i class="fa fa-phone" aria-hidden="true"></i>+91 9654854895</a></li>
                    <li><a href="mailto:contactus@patchinfotech.com"><i class="fa fa-phone" aria-hidden="true"></i>contactus@patchinfotech.com</a></li>
                </ul>
            </nav>
            <!-- .nav-menu -->

        </div>
    </header>
    <!-- End Header -->

    <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex align-items-center">
        <div class="container" data-aos="zoom-out" data-aos-delay="100">

            <div class="row d-flex align-items-center mt-lg-5">
                <div class="col-md-12 col-lg-6">
                    <div class="hero-content">
                        <div class="hero-titles">
                            <h1>There is <span>(literally)</span> nothing that we can’t help you with!</h1>
                        </div>
                        <div class="price-content">
                            <p>Every day we help hundreds of <span>unicorns, startups,</span> and <span>enterprises</span> with best-in-class bench resources on a contract basis to solve their complex <span>Web and App development challenges</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-1">
                    
                </div>
                <div class="col-md-12 col-lg-5">
                    <div class="hero-form contact">
                        <div class="form-title">
                            <h2>Augment Your Project <br>With Our Experts</h2>
                        </div>
                        
                        <form role="form" class="php-email-form" id="paymentForm" method="POST"
                        >
                            <div class="form-group">
                                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" />
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" name="phone_number" id="phone_number" placeholder="Phone Number"/>
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" name="email" id="email" placeholder="Email Address"/>
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="company" class="form-control" id="company" placeholder="Company Name" />
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="city" class="form-control" id="City" placeholder="City" />
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" id="Description" name="Description" placeholder="Description"></textarea>
                                <div class="validate"></div>
                            </div>
                            <div class="form-group">
                                <input type="text" name="Budget" class="form-control" id="Budget" placeholder="Budget" />
                                <div class="validate"></div>
                            </div>

                            <div class="text-center"><button type="submit" id="signup" class="form-sign-up">Request a Call Back</button></div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- End Hero -->

    <main id="main">

        <!-- ======= Services Section ======= -->
        <section id="services" class="services">
            <div class="container" data-aos="fade-up">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <h2>IT Staff Augmentation Services</h2>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="services-box">
                            <img src="../../assets/img/landing/services-1.png" class="img-fluid">
                            <p>Build a Team of Experts from Scratch</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="services-box">
                            <img src="../../assets/img/landing/services-2.png" class="img-fluid">
                            <p>Support Your Existing Project</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="services-box">
                            <img src="../../assets/img/landing/services-3.png" class="img-fluid">
                            <p>Switch Your Current Vendor</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Services Section -->

        <!-- ======= Patch Infotech Section ======= -->
        <section id="infotech" class="infotech">
            <div class="container" data-aos="fade-up">
                <div class="row d-flex align-items-center">
                    <div class="col-md-5">
                        <div class="infotech-image">
                             <img src="../../assets/img/landing/infotech.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="infotech-content">
                            <h2>This is <br><span>Patch Infotech</span></h2>
                            <p>Whether you want a single person or a team of tech professionals, we will help you build winning products. With the experience of building more than 20 high-end brands, Patch Infotech is not just another IT company but is a hub for global enterprises to get solutions for their complex web & mobile development problems with our top-notch IT Staff Augmentation Model.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Patch Infotech Section -->

        <!-- ======= Clients Section ======= -->
        <section id="clients" class="clients">
            <div class="container" data-aos="fade-up">
                <div class="row">
                    <div class="col-md-12">
                        <div class="section-title">
                            <h2>Clients that love us</h2>
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-01.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-02.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-03.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-04.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-05.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-06.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-07.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-08.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-09.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-10.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-11.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-12.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-13.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-14.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-15.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-3 col-6"> 
                        <div class="client-box">
                            <img src="../../assets/img/landing/Client/clayant-16.png" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="client-button">
                            <a href="/staff-augmentation#" (click)="onCTAClick()" >Augment your project </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Clients Section -->

        <!-- ======= Team Section ======= -->
        <section id="team" class="team">
            <div class="container" data-aos="fade-up">
                <div class="row justify-content-center text-center">
                    <div class="col-md-12">
                        <div class="team-title">
                            <h2>Build your team<br>and start your project in <span>just 3 days</span></h2>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Digital Product Engineering</h3>
                            <p>UI/UX | Frontend | API | Web |Mobile | Dev</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Cloud & DevOps</h3>
                            <p>DevOps Automation | SRE | Cloud Migrations | Cloud Spend Management</p>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Digital Transformation</h3>
                            <p>SEO | SEM | Creative & Design | Media | Email & Marketing Automation</p>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Quality Engineering</h3>
                            <p>Service quality assurance and control</p>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Chatbot Development</h3>
                            <p>Artificial Intelligence (AI) software service</p>
                        </div>
                    </div>
                    
                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Managed Services</h3>
                            <p>24X7 Managed Services</p>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="team-box">
                            <img src="../../assets/img/landing/right-sign.png" class="img-fluid">
                            <h3>Data & Analytics</h3>
                            <p>Data Engineering | BI & Data Analysis | Data Science & ML | Digital Analytics</p>
                        </div>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="team-btn text-center">
                            <a href="/staff-augmentation#" (click)="onCTAClick()" >Build your team</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End team Section -->

        <!-- =======Technologies Section ======= -->
        <section id="technologies" class="technologies">
            <div class="container" data-aos="zoom-in">
            	<div class="section-title">
            		<h2>Transform your idea into reality using cutting-edge technologies</h2>
            	</div>
            	<div class="col-lg-12 col-md-12" style="margin: 0 auto;">

	            <div class="row g-0 bg-color justify-content-center">
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/1.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/2.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/3.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/4.png" class="img-fluid">
                    </div>
	              </div>
	              <!-- <div class="custom-col-2">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/5.png" class="img-fluid">
                    </div>
	              </div> -->
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/6.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/7.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/8.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/9.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/10.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/11.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/12.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/13.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/14.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/15.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/16.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/17.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/18.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/19.png" class="img-fluid">
                    </div>
	              </div>
	              <!-- <div class="custom-col-2">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/20.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/21.png" class="img-fluid">
                    </div>
	              </div> -->
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/22.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/23.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/24.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/25.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/26.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/27.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/28.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/29.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/30.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/31.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/32.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/33.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/35.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/36.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/37.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/38.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/39.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/40.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/41.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/42.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/43.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/44.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/45.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/46.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/47.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/48.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/49.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/50.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/51.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/52.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/53.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/54.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/55.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/56.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/57.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/58.png" class="img-fluid">
                    </div>
	              </div>
	              <div class="custom-col-2 d-flex align-items-center">
	                <div class="technologies-box">
                        <img src="../../assets/img/landing/technologies/59.png" class="img-fluid">
                    </div>
	              </div>
	            </div>
	            <div class="col-md-12">
	            	<div class="technologies-btn text-center">
	            		<a href="/staff-augmentation#" (click)="onCTAClick()" >I want to build my team</a>
	            	</div>
	            </div>
          	</div>
            </div>
        </section>
        <!-- End Technologies Section -->

        <!-- Experts Section -->
        <section class="experts" id="experts">
        	<div class="container">
        		<div class="row d-flex align-items-center">
        			<div class="col-md-6">
        				<div class="expert-image">
        					<img src="../../assets/img/landing/expert.png" class="img-fluid">
        				</div>
        			</div>
        			<div class="col-md-6">
        				<div class="expert-content">
        					<h2>You got<br class="d-none d-md-block"> a challenge<br class="d-none d-md-block"> we got experts</h2>
        					<p>No matter which industry you cater to, we guarantee you <span>100% success</span></p>
        					<a href="/staff-augmentation#" (click)="onCTAClick()" >Contact us today!</a>
        				</div>
        			</div>
        		</div>
        	</div>
        </section>
        <!-- End Experts Section -->
    </main>
    <!-- End #main -->

    <!-- ======= Footer ======= -->
    <footer id="footer" class="d-none d-md-block d-lg-block">

        <div class="footer-top">
            <div class="container">
                <div class="row row-cols-md-5 no-gutters">
                    <div class="col-6">
                        <div class="who-are-box">
                            <h3>Who are we</h3>
                            <ul>
                                <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="what-we-do-box">
                            <h3>What we do</h3>
                            <ul>
                                <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                                <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                                <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                                <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="contact-box">
                            <h3>Contact us</h3>
                            <ul>
                                <li>
                                    <img src="../../assets/img/landing/call.png" class="img-fluid">
                                    <a href="tel:9654854895">+91-9654854895</a>
                                </li>
                                <li>
                                    <img src="../../assets/img/landing/email.png" class="img-fluid">
                                    <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="address-box">
                             <h3>Address</h3>
                             <p>91springboard, Building<br>
                                Number 145,Sector 44Rd,<br>
                                Sector 44,Gurgaon-122003 
                             </p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="footer-logo">
                            <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                        </div>
                        <div class="social-links mt-4">
                            <a href="https://www.facebook.com/Patch-Infotech-111521311585621" target="_blank"><img src="../../assets/img/landing/fb.png" class="img-fluid"></a>
                            <a href="https://instagram.com/patchinfotech?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/img/landing/instagram.png" class="img-fluid"></a>
                            <a href="https://www.linkedin.com/company/14373720/admin/" target="_blank"><img src="../../assets/img/landing/linkdin.png" class="img-fluid"></a>
                            <a href="https://twitter.com/patch_infotech" target="_blank"><img src="../../assets/img/landing/tweet.png" class="img-fluid"></a>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                            </div>
                            <div class="col-md-6">
                                <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>

     <footer id="footer" class="d-block d-md-none d-lg-none">

        <div class="footer-top" >
            <div class="container">
                <div class="row">
                	<div class="col-12">
                		<div class="who-are-box">
                			<h3>Who are we</h3>
                			<ul>
                				<li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                			</ul>
                		</div>
                	</div>
                	<div class="col-12">
                		<div class="what-we-do-box">
                			<h3>What we do</h3>
                			<ul>
                				<li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                				<li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                				<li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                				<li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                			</ul>
                		</div>
                	</div>
                	<div class="col-12">
                		<div class="contact-box">
                			<h3>Contact us</h3>
                			<ul>
                				<li>
                					<img src="../../assets/img/landing/call.png" class="img-fluid">
                					<a href="tel:9654854895">+91-9654854895</a>
                				</li>
                				<li>
                					<img src="../../assets/img/landing/email.png" class="img-fluid">
                					<a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                				</li>
                			</ul>
                		</div>
                	</div>
                	<div class="col-12">
                		<div class="address-box">
                			 <h3>Address</h3>
                			 <p>91springboard, Building<br>
                			 	Number 145,Sector 44Rd,<br>
                			 	Sector 44,Gurgaon-122003 
                			 </p>
                		</div>
                	</div>
                	<div class="col-12">
                		<div class="footer-logo text-center mt-4">
                			<img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                			<p>&#169;2020 Patch Infotech</p>
                		</div>
                        <div class="social-links text-center">
                            <a href="https://www.facebook.com/Patch-Infotech-111521311585621" target="_blank"><img src="../../assets/img/landing/fb.png" class="img-fluid"></a>
                            <a href="https://instagram.com/patchinfotech?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/img/landing/instagram.png" class="img-fluid"></a>
                            <a href="https://www.linkedin.com/company/14373720/admin/" target="_blank"><img src="../../assets/img/landing/linkdin.png" class="img-fluid"></a>
                            <a href="https://twitter.com/patch_infotech" target="_blank"><img src="../../assets/img/landing/tweet.png" class="img-fluid"></a>
                        </div>
                	</div>
                    <div class="col-md-12">
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                            </div>
                            <div class="col-md-6">
                                <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- End Footer -->

    <div id="preloader"></div>

