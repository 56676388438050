import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-inner',
  templateUrl: './blog-inner.component.html',
  styleUrls: ['./blog-inner.component.css']
})
export class BlogInnerComponent implements OnInit {

  constructor() { }


  ngOnInit(): void {
  }

}
