<!-- ======= Header ======= -->
<header id="header-thank">
    <div class="container d-flex align-items-center">

        <a href="/" class="logo mr-lg-auto"><img src="../../assets/img/landing/logo.png" alt=""></a>

        <nav class="nav-menu d-none">
            <ul>
                <li><a href="tel:+912235542771"><i class="fa fa-phone" aria-hidden="true"></i>+91 22-35542771</a></li>
                <li><a href="mailto:contactus@patchinfotech.com"><i class="fa fa-phone" aria-hidden="true"></i>contactus@patchinfotech.com</a></li>
            </ul>
        </nav>
        <!-- .nav-menu -->

    </div>
</header>
<!-- End Header -->

<main id="main">
    <!-- ======= Team Section ======= -->
    <section id="team" class="team section-bg">
      <div class="container" data-aos="fade-up">

        <div class="row">

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
            <a href="/blog-inner">
                <div class="member">
                  <div class="member-img">
                    <img src="../../assets/img/Blog/BOOt wallpeprw.png" class="img-fluid" alt="">
                  </div>
                  <div class="member-info">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                  </div>
                </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
            <a href="/blog-inner">
                <div class="member">
                  <div class="member-img">
                    <img src="../../assets/img/Blog/relience digital.png" class="img-fluid" alt="">
                  </div>
                  <div class="member-info">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                  </div>
                </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
            <a href="/blog-inner">
                <div class="member">
                  <div class="member-img">
                    <img src="../../assets/img/Blog/Tata Motors.png" class="img-fluid" alt="">
                  </div>
                  <div class="member-info">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                  </div>
                </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
            <a href="/blog-inner">
                <div class="member">
                  <div class="member-img">
                    <img src="../../assets/img/Blog/Amzon.png" class="img-fluid" alt="">
                  </div>
                  <div class="member-info">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                  </div>
                </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
            <a href="/blog-inner">
                <div class="member">
                  <div class="member-img">
                    <img src="../../assets/img/Blog/Nike.png" class="img-fluid" alt="">
                  </div>
                  <div class="member-info">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                  </div>
                </div>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="600">
            <a href="/blog-inner">
                <div class="member">
                  <div class="member-img">
                    <img src="../../assets/img/Blog/Dominos.png" class="img-fluid" alt="">
                  </div>
                  <div class="member-info">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since 
                      the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                  </div>
                </div>
            </a>
          </div>

        </div>

      </div>
    </section>
    <!-- End Team Section -->

  </main>
  <!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer" class="d-none d-md-block d-lg-block">

    <div class="footer-top">
        <div class="container">
            <div class="row row-cols-md-5 no-gutters">
                <div class="col-6">
                    <div class="who-are-box">
                        <h3>Who are we</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="what-we-do-box">
                        <h3>What we do</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                            <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                            <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                            <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="contact-box">
                        <h3>Contact us</h3>
                        <ul>
                            <li>
                                <img src="../../assets/img/landing/call.png" class="img-fluid">
                                <a href="tel:9654854895">+91-9654854895</a>
                            </li>
                            <li>
                                <img src="../../assets/img/landing/email.png" class="img-fluid">
                                <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="address-box">
                         <h3>Address</h3>
                         <p>91springboard, Building<br>
                            Number 145,Sector 44Rd,<br>
                            Sector 44,Gurgaon-122003 
                         </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-logo">
                        <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                        <p>&#169;2020 Patch Infotech</p>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                        </div>
                        <div class="col-md-6">
                            <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

 <footer id="footer" class="d-block d-md-none d-lg-none">

    <div class="footer-top">
        <div class="container">
            <div class="row row-cols-md-5 no-gutters">
                <div class="col-6">
                    <div class="who-are-box">
                        <h3>Who are we</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/about" target="_blank">About us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="what-we-do-box">
                        <h3>What we do</h3>
                        <ul>
                            <li><a href="https://patchinfotech.com/industries" target="_blank">Industires</a></li>
                            <li><a href="https://patchinfotech.com/technology" target="_blank">Technologies</a></li>
                            <li><a href="https://patchinfotech.com/services" target="_blank">Services</a></li>
                            <li><a href="https://patchinfotech.com/client" target="_blank">Clientele</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="contact-box">
                        <h3>Contact us</h3>
                        <ul>
                            <li>
                                <img src="../../assets/img/landing/call.png" class="img-fluid">
                                <a href="tel:9654854895">+91-9654854895</a>
                            </li>
                            <li>
                                <img src="../../assets/img/landing/email.png" class="img-fluid">
                                <a href="mailto:contactus@patchinfotech.com">contactus@patchinfotech.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-6">
                    <div class="address-box">
                         <h3>Address</h3>
                         <p>91springboard, Building<br>
                            Number 145,Sector 44Rd,<br>
                            Sector 44,Gurgaon-122003 
                         </p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="footer-logo">
                        <img src="../../assets/img/landing/footer-logo.png" class="img-fluid">
                    </div>
                    <div class="social-links mt-4">
                        <a href="https://www.facebook.com/Patch-Infotech-111521311585621" target="_blank"><img src="../../assets/img/landing/fb.png" class="img-fluid"></a>
                        <a href="https://instagram.com/patchinfotech?igshid=YmMyMTA2M2Y=" target="_blank"><img src="../../assets/img/landing/instagram.png" class="img-fluid"></a>
                        <a href="https://www.linkedin.com/company/14373720/admin/" target="_blank"><img src="../../assets/img/landing/linkdin.png" class="img-fluid"></a>
                        <a href="https://twitter.com/patch_infotech" target="_blank"><img src="../../assets/img/landing/tweet.png" class="img-fluid"></a>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <p style="font-size: 16px;">&#169;2020 Patch Infotech</p>
                        </div>
                        <div class="col-md-6">
                            <a href="privacy-policy" style="color: #fff; float: right; font-size: 16px;">Privacy policy</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer -->
<div id="preloader"></div>
