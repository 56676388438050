<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
      <div class="textTopBannerHomePage">
          <h2>We are <span class="color"> Patch Infotech </span></h2>
          <p>One idea can change the world. New ideas will discover new worlds.</p>
          <a href="">Know More</a>
      </div>
      </div>
        </div>
    </div>
<div class="container">
    <div class="serviceSection">
        <div class="headingHome">
            <h2>Services We Provide</h2>
        </div>

        <div class="col-sm-4 fstHomeTop" style="padding-left: 0px;">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 402.png">
                </div>
                <div class="serviceTitle">
                    <p>Digital Product <br> Engineering</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 405.png">
                </div>
                <div class="serviceTitle">
                    <p>Cloud & <br> Devops</p>
                </div>
            </div>
        </div>

        <div class="col-sm-4 fstHomeTop">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 404.png">
                </div>
                <div class="serviceTitle">
                    <p>Digital <br> Transformation</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop" style="padding-left: 0px;">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 400.png">
                </div>
                <div class="serviceTitle">
                    <p>Quality <br> Engineering</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 407.png">
                </div>
                <div class="serviceTitle">
                    <p>Chatbot <br> Development</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 403.png">
                </div>
                <div class="serviceTitle">
                    <p>Managed <br> services</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop" style="padding-left: 0px;">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 399.png">
                </div>
                <div class="serviceTitle">
                    <p>Staff <br> agumentation</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 401.png">
                </div>
                <div class="serviceTitle">
                    <p>Customized <br> CRM Solutions</p>
                </div>
            </div>
        </div>
        <div class="col-sm-4 fstHomeTop">
            <div class="serviceSectionSlid">
                <div class="serviceLogo">
                    <img src="../../assets/img/Home page/Group 398.png">
                </div>
                <div class="serviceTitle">
                    <p>Data & <br> Analytics</p>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container">
    <div class="serviceSection">
        <div class="headingHome">
            <h2>Why Choose us?</h2>
        </div>
        <div class="textWrap">
            
            <span style="line-height:24px;">We work selflessly “To serve established and emerging organisations with the <br> best IT services to help them achieve their ultimate goals.”</span>
            <p>We design meaningful digital products & experiences for people. We leave no stone unturned while working so that we can deliver our clients the best. It makes us feel highly proud and encouraged when our clients appreciate our work.</p>
        </div>
<div class="threeDivWrap">
    <div class="col-sm-4 fstHomeTop" style="padding-left:0px;">
        <div class="threeDivWrapColom">
        <div class="imgWrap">
            <img src="../../assets/img/Home page/Group 358.png" alt="">
        </div>
        <h2 class="subHeading">Excellence is our habit</h2>
        <p>We settle for nothing less! We create designs and code to inspire and attract.</p>
</div>
    </div>
    <div class="col-sm-4 fstHomeTop">
        <div class="threeDivWrapColom">
        <div class="imgWrap">
            <img src="../../assets/img/Home page/Group 237.png" alt="">
        </div>
        <h2 class="subHeading">Our Flexibility</h2>
        <p>Flexibility allows us to provide our clients with a method that truly suits their individual needs.</p>
</div>
    </div>
    <div class="col-sm-4 fstHomeTop">
        <div class="threeDivWrapColom">
        <div class="imgWrap">
            <img src="../../assets/img/Home page/Group 236.png" alt="">
        </div>
        <h2 class="subHeading">Quality of our services</h2>
        <p>With 35+ Developers & Designers team we maintained our delivery quality to such an extent that our successful deployment of projects and resources is above 95%.</p>
</div>
    </div>
</div>
</div>
</div>
  <div class="container">
      <div class="technology">
          <div class="heading">
            <h2>Technologies</h2>
          </div>
          <p class="topP">We solve complex problems using cutting edge technologies</p>
          <p class="secondP">We take a deep dive into various digital advances and leverage these technologies to build winning products. We work on breakthrough technologies and deliver mission critical mobile and web applications. Some of the technologies that we have mastered include Grails, Angular.js, Node.js, and React to name a few.</p>
          <div class="button">
            <a [routerLink]="['/technology']" style="background: rgba(74,120,235,.7) !important;">View All Technologies</a>
          </div>
      </div>

  </div>



<div class="container">
    <div class="serviceSection">
        <div class="headingHome">
            <h2>Featured Projects</h2>
        </div>
        <div class="featuredProject">
            <div class="col-sm-4 fstHomeTop" style="padding-left:0px;">
                <div class="imgProduct">
                    <a [routerLink]="['/boost']">
                    <img src="../../assets/img/Home page/Group 643/Group 643.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop">
                <div class="imgProduct">
                    <a [routerLink]="['/gromo']">
                    <img src="../../assets/img/Home page/Group 631.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop">
                <div class="imgProduct">
                    <a [routerLink]="['/chqbook']">
                    <img src="../../assets/img/Group 810@2x.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop" style="padding-left:0px">
                <div class="imgProduct">
                    <a [routerLink]="['/irctc']">
                    <img src="../../assets/img/Home page/Group 642.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop">
                <div class="imgProduct">
                    <a [routerLink]="['/dubai']">
                    <img src="../../assets/img/Home page/Group 641.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop">
                <div class="imgProduct">
                    <a [routerLink]="['/beatroute']">
                    <img src="../../assets/img/Home page/Group 630.png">
                    </a>
                </div>
            </div>

            <div class="button btnProduct">
                <a [routerLink]="['/featured-products']" >View All Projects</a>
              </div>
         
        </div>
    </div>

</div>

<div class="container">
    <div class="serviceSection">
        <div class="headingHome">
            <h2>Our Products</h2>
        </div>
        <div class="featuredProject">
            <div class="col-sm-4 fstHomeTop " style="padding-left: 0px;">
                <div class="imgProject">
                    <a href="http://contrastaffing.com/">
                    <img src="../../assets/img/Home page/Group 628.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop">
                <div class="imgProject">
                    <a href="">
                    <img src="../../assets/img/Home page/Group 627.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 fstHomeTop">
                <div class="imgProject">
                    <a href="">
                    <img src="../../assets/img/Home page/Group 626.png">
                    </a>
                </div>
            </div>
  
         
        </div>
    </div>

</div>

<div class="container">
    <div class="technology clintwrap" style="padding-top:0px;">
        <div class="headingHome">
            <h2 style="color:#fff;">Our Clients</h2>
        </div>
        <div class="clintList desk">
            <ul>
                <li><img src="../../assets/img/Home page/Group 133.png"></li>
                <li><img src="../../assets/img/Home page/Group 134.png"></li>
                <li><img src="../../assets/img/Home page/Group 135.png"></li>
                <li><img src="../../assets/img/Home page/Group 136.png"></li>
                <li><img src="../../assets/img/Home page/Group 137.png"></li>
            </ul>
            <ul>
                <li><img src="../../assets/img/Home page/Group 138.png"></li>
                <li><img src="../../assets/img/Home page/Group 139.png"></li>
                <li><img src="../../assets/img/Home page/Group 140.png"></li>
                <li><img src="../../assets/img/Home page/Group 141.png"></li>
                <li><img src="../../assets/img/Home page/Group 142.png"></li>
            </ul>
            <ul>
                <li><img src="../../assets/img/Home page/Group 143.png"></li>
                <li><img src="../../assets/img/Home page/Group 144.png"></li>
                <li><img src="../../assets/img/Home page/Group 145.png"></li>
                <li><img src="../../assets/img/Home page/Group 146.png"></li>
                <li><img src="../../assets/img/Home page/Group 147.png"></li>
            </ul>
            <ul>
                <li><img src="../../assets/img/Home page/Group 148.png"></li>
                <li><img src="../../assets/img/Home page/Group 149.png"></li>
                <li><img src="../../assets/img/Home page/Group 150.png"></li>
                <li><img src="../../assets/img/Home page/Group 151.png"></li>
                <li><img src="../../assets/img/Home page/Group 152.png"></li>
            </ul>
            
        </div>
    
    <div class="clintList mobile">
        <ul>
            <li><img src="../../assets/img/Home page/Group 133.png"></li>
            <li><img src="../../assets/img/Home page/Group 134.png"></li>
            <li><img src="../../assets/img/Home page/Group 135.png"></li>
            <li><img src="../../assets/img/Home page/Group 136.png"></li>
            <li><img src="../../assets/img/Home page/Group 137.png"></li>
            <li><img src="../../assets/img/Home page/Group 138.png"></li>
        </ul>
        <ul>
            <li><img src="../../assets/img/Home page/Group 139.png"></li>
            <li><img src="../../assets/img/Home page/Group 140.png"></li>
            <li><img src="../../assets/img/Home page/Group 141.png"></li>
            <li><img src="../../assets/img/Home page/Group 142.png"></li>
            <li><img src="../../assets/img/Home page/Group 143.png"></li>
            <li><img src="../../assets/img/Home page/Group 144.png"></li>
        </ul>
        <ul>
            <li><img src="../../assets/img/Home page/Group 145.png"></li>
            <li><img src="../../assets/img/Home page/Group 146.png"></li>
            <li><img src="../../assets/img/Home page/Group 147.png"></li>
            <li><img src="../../assets/img/Home page/Group 148.png"></li>
            <li><img src="../../assets/img/Home page/Group 149.png"></li>
            <li><img src="../../assets/img/Home page/Group 150.png"></li>
            
        </ul>
        <ul>
            <li><img src="../../assets/img/Home page/Group 151.png"></li>
            <li><img src="../../assets/img/Home page/Group 152.png"></li>
        </ul>
        
    </div>
</div>

<div class="container" style="padding-left: 0px;">
    <div class="serviceSection">
        <div class="headingHome">
            <h2>Our Extensive Reach</h2>
        </div>
        <div class="featuredProject">
            <div class="col-sm-4 paddingLes fstHomeTop">
                <div class="imgReach">
                    <a href="">
                    <img src="../../assets/img/Home page/re/Group 813@2x.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 paddingLes fstHomeTop">
                <div class="imgReach">
                    <a href="">
                    <img src="../../assets/img/Home page/re/Group 814@2x.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 paddingLes fstHomeTop">
                <div class="imgReach">
                    <a href="">
                    <img src="../../assets/img/Home page/re/Group 815@2x.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 paddingLes fstHomeTop">
                <div class="imgReach">
                    <a href="">
                    <img src="../../assets/img/Home page/re/Group 816@2x.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 paddingLes fstHomeTop">
                <div class="imgReach">
                    <a href="">
                    <img src="../../assets/img/Home page/re/Group 817@2x.png">
                    </a>
                </div>
            </div>
            <div class="col-sm-4 paddingLes fstHomeTop">
                <div class="imgReach">
                    <a href="">
                    <img src="../../assets/img/Home page/re/Group 818@2x.png">
                    </a>
                </div>
            </div>

            <div class="button btnProduct fstHomeTop">
                <a [routerLink]="['/industries']" >View All Industries</a>
              </div>
         
        </div>
    </div>

</div>
</div>
<div class="container-flued bottomallpages">
    <div class="container">
 <div class="bottomLast">
     <div class="col-sm-8 fstHomeTop">
     <div class="firstBottomText">
        <h2>Interested To Get Our Featured Service?</h2>
     </div>
     </div>
     <div class="col-sm-4">
        <div class="firstBottomText">
           <a [routerLink]="['/contact']">Connect with Us</a>
        </div>
        </div>
        </div>
     </div>
 </div>

 <!-- <div class="container">
    <div class="homePgeBnner">
      <div class="textTopBannerHomePage">
          <h2>We are <span class="color"> Patch Infotech </span></h2>
          <p>One idea can change the world. New ideas will discover new worlds.</p>
          <a href="">Know More</a>
      </div>
      
        </div>
    </div> -->