import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beatroute',
  templateUrl: './beatroute.component.html',
  styleUrls: ['./beatroute.component.scss']
})
export class BeatrouteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
