<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
        <div class="textTopBannerHomePage">
            <h2>Industries we served so far </h2>
            <p>Leveraging the power of disruptive digital technologies, we empower businesses across industries to
                transform digitally</p>
        </div>
    </div>
</div>

<div class="container">
    <div class="serviceSection">
        <div class="headingHome">
            <h2>Our extensive reach</h2>
        </div>
        <div class="textWrap">
            <p>We deliver bespoke industry specific solutions leveraging our extensive digital experience, design-led
                engineering approach and agile processes backed by our strong expertise in cutting edge technologies.
            </p>
        </div>
        <div class="secondWrap desk">
            <div class="col-sm-5 fstHomeTop">
                <div class="textWrap1 left">
                    <h2>Automobile & Transport</h2>
                    <p>Mobility, Booking platforms, Marketplaces,</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 558.png">
                </div>

                <div class="textWrap1 left">
                    <h2>Finance & Fintech</h2>
                    <p>Banking, Trading, Investments</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 566.png">
                </div>


                <div class="textWrap1 left">
                    <h2>Hospitality & Real estate</h2>
                    <p>Apartment renting app, CRMs,Customer Apps</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/shutterstock_660775510.png">
                </div>


            </div>
            <div class="col-sm-1 long" style="display: block; text-align: center;">
                <img src="../../assets/img/Industries/Group 650.png"
                    style="height: 1522px; display:inline-block; text-align:center;">
            </div>
            <div class="col-sm-5 fstHomeTop">
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 557.png">
                </div>
                <div class="textWrap1">
                    <h2>Agritech</h2>
                    <p>Farming, Supply chain, Procurement, Delivery</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 559.png">
                </div>
                <div class="textWrap1">
                    <h2>Education & Learning</h2>
                    <p>Cloud & Hybrid Platforms, Micro Learning</p>
                </div>

                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 567.png">
                </div>

                <div class="textWrap1">
                    <h2>Healthcare & Wellness</h2>
                    <p>Mobility, Booking platforms, Marketplaces,</p>
                </div>

            </div>
        </div>

        <!--  mobile -->
        <div class="secondWrap mob">
            <div class="col-sm-5">
                <div class="textWrap1 left">
                    <h2>Automobile & Transport</h2>
                    <p>Mobility, Booking platforms, Marketplaces,</p>
                </div>

                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 557.png">
                </div>

                <div class="textWrap1 left">
                    <h2>Finance & Fintech</h2>
                    <p>Banking, Trading, Investments</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 559.png">
                </div>


                <div class="textWrap1 left">
                    <h2>Hospitality & Real estate</h2>
                    <p>Apartment renting app, CRMs,Customer Apps</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 567.png">
                </div>


            </div>

            <div class="col-sm-5">
                <div class="textWrap1">
                    <h2>Agritech</h2>
                    <p>Farming, Supply chain, Procurement, Delivery</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 558.png">
                </div>
                <div class="textWrap1">
                    <h2>Education & Learning</h2>
                    <p>Cloud & Hybrid Platforms, Micro Learning</p>
                </div>
                <div class="imgWrap">
                    <img src="../../assets/img/Industries/Group 566.png">
                </div>
                <div class="textWrap1">
                    <h2>Healthcare & Wellness</h2>
                    <p>Mobility, Booking platforms, Marketplaces,</p>
                </div>

                <div class="imgWrap">
                    <img src="../../assets/img/Industries/shutterstock_660775510.png">
                </div>

            </div>
        </div>
        <!--  mobile -->

    </div>
</div>
<div class="container-flued bottomallpages">
    <div class="container">
        <div class="bottomLast">
            <div class="col-sm-8">
                <div class="firstBottomText">
                    <h2>Interested To Get Our Featured Service?</h2>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="firstBottomText">
                    <a [routerLink]="['/contact']">Connect with Us</a>
                </div>
            </div>
        </div>
    </div>
</div>
</div>