<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Chqbook.com </h2>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="boostMainWrap">
            <div class="col-sm-5">
                <div class="boostImageWrap" style="height: auto;">
                    <img src="../../../assets/img/Chqbook.com/Group 729.png">
                </div>
            </div>
            <div class="col-sm-7">
                <div class="rightPROduct">
                    <div class="noLogo">
                        <img src="../../../assets/img/Chqbook.com/Group 727.png" alt="">
                    </div>
                    <div class="noLogo">
                        <img src="../../../assets/img/Chqbook.com/Group 728.png" alt="" style="max-width: 70%;">
                    </div>
                    <div class="textProductRight">
                        <p>The app for finance frofessionals</p>
                    </div>
                    <div class="rightBottom">
                        <div class="col-sm-6" style="padding-left:0px;">
                            <p class="bottomProductLast">Website Link - <a
                                    href="https://www.chqbook.com">https://www.chqbook.com</a></p>
                        </div>
                        <div class="col-sm-6" style="padding-left:0px; margin-top: 10px;">
                            <p class="bottomProductLast"> Available in - <img
                                    src="../../../assets/img/Chqbook.com/Rectangle 279.png"> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="boostMainWrap thisRight">

            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Chqbook.com/Our Porfolio-09 2.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Chqbook.com/Our Porfolio-09 3.png">
                </div>
            </div>
            <div class="col-sm-4">
                <div class="boostImageWrap">
                    <img src="../../../assets/img/Chqbook.com/Our Porfolio-09.png">
                </div>
            </div>
        </div>
    </div>
    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a [routerLink]="['/contact']">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>