<div class="container-flued">
    <div class="homePgeBnner">
        <div class="container">
            <div class="textTopBannerHomePage">
                <h2>Services </h2>
                <p>Our featured services</p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="serviceSection">
            <div class="textWrap">
                <p>We help Internet-based businesses and product companies design and develop cloud-native web and
                    mobile solutions. We drive digital transformation for businesses by helping them provide a rich,
                    seamless experience to their customers across digital channels resulting in higher engagement,
                    efficiency, and profitability..</p>
            </div>
            <div class="threeDivWrap">
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 402.png" alt="">
                        </div>
                        <h2 class="subHeading">Digital Product Engineering</h2>
                        <p>UI/UX | Frontend | API | Web | Mobile | Dev</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 405.png" alt="">
                        </div>
                        <h2 class="subHeading">Cloud & Devops</h2>
                        <p>DevOps Automation | SRE | Cloud Migrations | Cloud Spend Management</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 404.png" alt="">
                        </div>
                        <h2 class="subHeading">Digital Transformation</h2>
                        <p>SEO | SEM | Creative & Design | Media | Email & Marketing Automation | Digital Analytics</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 400.png" alt="">
                        </div>
                        <h2 class="subHeading">Quality Engineering</h2>
                        <p>Service quality assurance and control</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 407.png" alt="">
                        </div>
                        <h2 class="subHeading">Chatbot Development</h2>
                        <p>Artificial Intelligence (AI) software service</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 403.png" alt="">
                        </div>
                        <h2 class="subHeading">Managed Services</h2>
                        <p>24X7 Managed Services</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 399.png" alt="">
                        </div>
                        <h2 class="subHeading">Staff Agumentation</h2>
                        <p>Staff outsourcing for a project</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 401.png " alt="">
                        </div>
                        <h2 class="subHeading">CRM</h2>
                        <p>Customized CRM Solutions</p>
                    </div>
                </div>
                <div class="col-sm-4 fstHomeTop">
                    <div class="threeDivWrapColom">
                        <div class="imgWrap">
                            <img src="../../assets/img/Home page/Group 398.png" alt="">
                        </div>
                        <h2 class="subHeading">Data & Analytics</h2>
                        <p>Data Engineering | BI & Data Analysis | Data Science & ML | Digital Analytics</p>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container-flued bottomallpages">
        <div class="container">
            <div class="bottomLast">
                <div class="col-sm-8">
                    <div class="firstBottomText">
                        <h2>Interested To Get Our Featured Service?</h2>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="firstBottomText">
                        <a href="">Connect with Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>